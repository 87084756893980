<template>
  <v-layout column>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      :loading="loading"
      :options.sync="options"
      :server-items-length="options.totalItems"
      :items-per-page="5"
    >
      <v-progress-linear slot="progress" indeterminate></v-progress-linear>
      <template v-slot:item.eventTime="{ value }">
        {{ value | dateTimeFilter }}
      </template>
    </v-data-table>
  </v-layout>
</template>
<script>
import { Header } from "../../common/Common";
import dateTimeFilters from "../../mixins/date-time-filters";
import ReportsService from "../../services/ReportsService.js";
export default {
  name: "LoginHistoryTable",
  mixins: [dateTimeFilters],
  props: {
    startDate: {
      required: true
    },
    endDate: {
      required: true
    }
  },
  data() {
    return {
      headers: [
        new Header(this.$t("loginHistoryReport.eventDate"), "eventTime"),
        new Header(this.$t("marketingReport.insertUser"), "userLogin"),
        new Header(
          this.$t("loginHistoryReport.eventDescription"),
          "description"
        )
      ],
      items: [],
      options: {},
      loading: false
    };
  },
  watch: {
    options() {
      if (this.items.length > 0) {
        this.getData();
      }
    },
    loading() {
      this.$emit("processing", !this.loading);
    }
  },
  methods: {
    getData() {
      this.loading = true;
      ReportsService.GetLoginHistoryReport(
        this.startDate,
        this.endDate,
        this.options.page,
        this.options.itemsPerPage
      ).then(response => {
        this.items = response.items;
        this.loading = false;
        this.options.totalItems = response.totalItems;
      });
    }
  }
};
</script>
<style scoped lang="scss"></style>
