const options = {
  name: "_blank",
  specs: ["fullscreen=no", "titlebar=yes", "scrollbars=no"],
  landscape: false
};

function _createStyles(landscape) {
  var styles = document.getElementsByTagName("style");
  var styleString = [].slice
    .call(styles)
    .map(function(item) {
      return item.innerHTML;
    })
    .join("");
  if (landscape) {
    styleString += "@page { size: landscape; }";
  }
  return styleString;
}

function _createHeadLinks() {
  var links = document.getElementsByTagName("link");
  var linksArray = [].slice
    .call(links)
    .filter(item => {
      return item.href.indexOf("css") > 0;
    })
    .map(item => {
      return item.outerHTML;
    })
    .join("");
  return linksArray;
}

function _createElementsHtml(elements) {
  if (Array.isArray(elements)) {
    return elements
      .map(function(item) {
        return `<p>${(item.$el || item).innerHTML}</p>`;
      })
      .join("");
  }
  return elements.innerHTML;
}
function _pageRender(title, body, landscape) {
  return `<html>
    <head>
      <title>${title}</title>
      <style type="text/css">
          ${_createStyles(landscape)}
      </style>
      <style type="text/css">
          button {
              display: none !important;
          }
          .v-input__icon {
              display: none !important;
          }
          .v-table__overflow {
              overflow-x: unset !important;
              overflow-y: unset !important;
          }
          .hideOnPrint{
              display: none !important;
          }
      </style>
      ${_createHeadLinks()}
    </head>
      <body>
      <div data-app="true" class="application theme--light" id="app">
          <div class="application--wrap">
              <main class="v-main" data-booted="true" style="padding: 0px;">
                  <div class="v-main__wrap">
                      ${body}
                  </div>
              </main>
          </div>
      </div>
      </body>
    </html>`;
}
export default {
  PrintElement(elements, title = "", opts) {
    let { name, specs, replace, landscape } = Object.assign({}, options, opts);

    if (!elements) {
      alert(`Elements to print not found!`);
      return;
    }
    console.log("print");

    const win = window.open("", name, specs.join(","), replace);
    win.document.write(
      _pageRender(title, _createElementsHtml(elements), landscape)
    );

    setTimeout(() => {
      win.document.close();
      win.focus();
      win.print();
      win.close();
    }, 1000);

    return true;
  }
};
