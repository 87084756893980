<template>
  <v-layout column>
    <v-layout justify-end align-center row wrap>
      <v-flex xs3>
        <v-layout>
          <v-flex>
            <v-menu
              v-model="showDataPicker.start"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="startDate"
                  :label="$t('backup.dateFrom')"
                  readonly
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                locale="pl"
                color="primary"
                :max="endDate"
                @change="showDataPicker.start = false"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex>
            <v-menu
              v-model="showDataPicker.end"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="endDate"
                  :label="$t('backup.dateTo')"
                  readonly
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                locale="pl"
                color="primary"
                :min="startDate"
                :max="currentDate"
                @change="showDataPicker.end = false"
              ></v-date-picker>
            </v-menu>
          </v-flex>
        </v-layout>
      </v-flex>
      <div>
        <v-btn color="primary" @click="generateReport">{{
          $t("global.generateReport")
        }}</v-btn>
      </div>
    </v-layout>
    <LoginHistoryTable
      :ref="tableRefName"
      :start-date="startDate"
      :end-date="endDate"
      @processing="dataProcessing"
    ></LoginHistoryTable>
    <v-layout>
      <PrintSave
        :disabled="!dataLoaded"
        :file-url="downloadReportUrl"
        @printClick="printReport"
      ></PrintSave>
    </v-layout>
  </v-layout>
</template>
<script>
import moment from "moment";
import Printer from "../common/HtmlPrinter";
import LoginHistoryTable from "../components/Reports/LoginHistoryTable";
import PrintSave from "../components/PrintSave";
import dateTimeFilters from "../mixins/date-time-filters";
import FileService from "../services/FileService.js"

export default {
  name: "ReportLoginHistory",
  components: { LoginHistoryTable, PrintSave },
  data() {
    const currentDate = dateTimeFilters.filters.dateFilter(moment());

    return {
      tableRefName: "table",
      dataLoaded: false,
      currentDate,
      startDate: dateTimeFilters.filters.dateFilter(
        moment().subtract(7, "days")
      ),
      endDate: currentDate,
      showDataPicker: { start: false, end: false },
      lastRequest: {}
    };
  },
  computed: {
    printTitle() {
      return `${this.$t("loginHistoryReport.loginHistoryFrom")} ${
        this.lastRequest.startDate
      } ${this.$t("global.to").toLowerCase()} ${this.lastRequest.endDate}`;
    },
    downloadReportUrl() {
      return FileService.ReportLoginHistoryUrl(this.lastRequest.startDate, this.lastRequest.endDate);
    }
  },
  methods: {
    dataProcessing(loading) {
      this.dataLoaded = loading;
    },
    generateReport() {
      this.lastRequest = { startDate: this.startDate, endDate: this.endDate };
      this.$refs[this.tableRefName].getData();
    },
    printReport() {
      Printer.PrintElement(this.$refs[this.tableRefName].$el, this.printTitle, {
        landscape: true
      });
    }
  }
};
</script>
