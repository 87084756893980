<template>
  <v-layout v-if="canPrintSave" justify-end row>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <span v-on="on">
          <v-btn
            text
            icon
            color="primary"
            :disabled="disabled"
            @click="printReport"
          >
            <fa-icon icon="print" size="lg"></fa-icon>
          </v-btn>
        </span>
      </template>
      <span>{{ $t("global.print") }}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <span v-on="on">
          <v-btn text icon color="primary" :disabled="disabled" :href="fileUrl">
            <fa-icon icon="save" size="lg"></fa-icon>
          </v-btn>
        </span>
      </template>
      <span>{{ $t("global.save") }}</span>
    </v-tooltip>
  </v-layout>
</template>
<script>
import { restrictions } from "../common/RestrictionsCode";
export default {
  name: "PrintSaveButtons",
  props: {
    disabled: {
      type: Boolean,
      default: true
    },
    fileUrl: {
      type: String
    }
  },
  computed: {
    canPrintSave() {
      return this.$can("hasRestriction", { code: restrictions.dataSave });
    }
  },
  methods: {
    printReport() {
      this.$emit("printClick");
    }
  }
};
</script>
