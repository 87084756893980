import axios from "../httpConfig/http.js";
import store from "../store/index";

const urlApi = "api/reports";

export default {
  GetContactAgreementReport(isAgree, page, pageSize) {
    var params = {
      isAgree: isAgree,
      Page: page,
      PageSize: pageSize,
      UserName: store.state.user.login
    };
    return axios.get(`${urlApi}/contactAgreement/`, { params });
  },
  GetLoginHistoryReport(startDate, endDate, page, pageSize) {
    var params = {
      StartDate: startDate,
      EndDate: endDate,
      Page: page,
      PageSize: pageSize,
      UserName: store.state.user.login
    };
    return axios.get(`${urlApi}/loginHistory/`, { params });
  },

  GetOperationHistoryReport(
    startDate,
    endDate,
    firstname,
    surname,
    phone,
    mail,
    page,
    pageSize
  ) {
    var params = {
      StartDate: startDate,
      EndDate: endDate,
      FirstName: firstname,
      Surname: surname,
      Phone: phone,
      Mail: mail,
      Page: page,
      PageSize: pageSize,
      UserName: store.state.user.login
    };
    return axios.get(`${urlApi}/operationHistory/`, { params });
  },

  GetContactQuarantineReport(page, pageSize) {
    var params = {
      Page: page,
      PageSize: pageSize,
      UserName: store.state.user.login
    };
    return axios.get(`${urlApi}/contactQuarantine/`, { params });
  }
};
