const baseFileUrl = "api/file";

export default {
  ReportContactAgreementUrl(withAgreement) {
    return `${baseFileUrl}/reportContactAgreement?isAgree=${withAgreement}`;
  },
  ReportContactQuarantineUrl() {
    return `${baseFileUrl}/reportContactQuarantine`;
  },
  ReportLoginHistoryUrl(startDate, endDate) {
    return `${baseFileUrl}/loginHistory?startDate=${startDate}&endDate=${endDate}`;
  },
  ReportOperationHistoryUrl(startDate, endDate, firstname, surname, phone, mail) {
    return `${baseFileUrl}/operationHistory?startDate=${startDate}&endDate=${endDate}&firstName=${firstname}&surname=${surname}&phone=${phone}&mail=${mail}`;
  }
}